.Header
  background-image: radial-gradient(60% 80% at 0% 0%, rgba(255, 255, 255, 0.35) 0%, transparent 100%), radial-gradient(40% 70% at 100% 0%, rgba(0, 204, 254, 0.35) 0%, transparent 100%)

  @media (max-width: 1024px)
    background-image: radial-gradient(100% 80% at 0% 0%, rgba(255, 255, 255, 0.26) 0%, transparent 100%),radial-gradient(80% 70% at 100% 0%, rgba(0, 204, 254, 0.2) 0%, transparent 100%)

  h1
    span.highligthed
      &::after
        position: relative
        left: 260px
        top: -10px
        display: flex
        mask-image: url("/icons/zigouigoui.svg")
        background-color: #DEDEDE
        background-size: 250px 40px
        background-repeat: no-repeat
        width: 200px
        height: 20px
        content: ''


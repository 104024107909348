.hsFormContainer > form {
    @apply flex w-full flex-col gap-3 font-body sm:gap-4;
}

.hsFormContainer fieldset:has(input[type='hidden']) {
    /* NOTE_LT : if not supported (old browsers), could create a small gap.. */
    @apply hidden;
}
.hsFormContainer fieldset[class*='form-column'] {
    min-width: 100% !important;
}

.hsFormContainer
    div[class*='hs-form-field']:not(
        div[class*='legal-consent-container'] *
    ):not(div[class*='recaptcha']) {
    @apply rounded-sm bg-white px-3 py-2.5 shadow transition focus-within:shadow-lg focus-within:ring focus-within:ring-cyprus-default !important;
}

/* .hsFormContainer
    div[class*='hs-form-field']:not(
        div[class*='legal-consent-container'] *
    ):not(div[class*='recaptcha'])
    > label:nth-child(1) {
    @apply pb-2 !important;
} */

.hsFormContainer label {
    @apply font-medium;
}
.hsFormContainer fieldset[class*='form-columns-2'] {
    @apply flex gap-3 sm:gap-4;
}

.hsFormContainer input:not(input[type='checkbox']):not(input[type='submit']),
.hsFormContainer textarea {
    @apply w-full font-light text-neutral-800 outline-none focus:outline-none focus:ring-0 !important;
}

.hsFormContainer textarea {
    @apply h-32;
}

.hsFormContainer div[class*='input']:not(div[class*='recaptcha'] *) {
    @apply m-0 mt-1 w-full  !important;
    /* NOTE_LT : Att, applies to last fields as well (recaptcha etc.) */
}

.hsFormContainer label[class*='hs-error-msg'] {
    @apply mb-0 mt-2 block text-xs font-normal leading-normal text-red-500 !important;
}

.hsFormContainer span[class*='hs-form-required'] {
    @apply before:content-['\0020'];
}

/* NOTE_LT : Spacing out last elements of the form */

.hsFormContainer div[class*='legal-consent-container'] {
    @apply mt-2;
}
.hsFormContainer div[class*='hs_recaptcha'] {
    @apply my-4;
}

.hsFormContainer div[class*='legal-consent-container'] * {
    @apply text-sm leading-relaxed;
}

.hsFormContainer
    div[class*='legal-consent-container']
    div[class*='hs-form-field'] {
    @apply my-2;
}

.hsFormContainer
    label[class*='hs-form-booleancheckbox-display']
    input[type='checkbox'] {
    @apply mt-1;
}

.hsFormContainer label[class*='hs-form-booleancheckbox-display'] span {
    @apply font-normal;
}

.hsFormContainer input[type='submit'] {
    @apply w-full cursor-pointer rounded bg-cyprus-default p-4 text-white shadow disabled:bg-gray-300 sm:hover:bg-cyprus-darker;
}

.hsFormContainer div[class*='hs_submit'] {
    @apply w-full;
}

.hsFormContainer div[class*='submitted-message'] {
    @apply mb-12 mt-8 font-body 2lg:mt-8;
}
